// Do not touch: used by webpack to bundle CSS
import styles from './../css/main.css';

// Libraries
import './utils/modernizr-custom-3.5.0'
import $ from 'jquery'
//import Headroom from 'headroom.js'

// Only if one needs to support particular touch interactions
//import TouchEmulator from 'hammer-touchemulator'
//import Hammer from 'hammerjs'

// Detects
import MQ from './utils/mq'

// Polyfills
// Note: arrow functions, const, let, template literals, classes and special characters
// are transformed by babel at compile time
import 'core-js/es6/promise' // instead of import 'babel-polyfill' -> https://stackoverflow.com/a/36350399
import 'core-js/es6/array' // for Array.from
import objectFitImages from 'object-fit-images'

// Other utils
import GDPRElement from './modules/gdpr/gdpr'
import './modules/gdpr/ga'

GDPRElement('.gdpr-ga-state');
GDPRElement('.gdpr-ga-alert');

const mq = MQ([320, 480, 586, 640, 768, 840, 960, 1024, 1280, 1400]); // available breakpoints

checkIfOver320();
$(window).on('resize', checkIfOver320);

function checkIfOver320() {
  if(mq.over320) { // Configure viewports
    console.log('viewport over 320px');
  }
}

objectFitImages();

(function($) {
    $(document).ready(function() {
      $('.variable-width').slick({
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        variableWidth: true
      });

      $('.single-item').slick();
      });

      $('.responsive').slick({
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 940,
            settings: {
              slidesToShow: 1,
              infinite: true,
              variableWidth: true
            }
          },
        ]
      });

      $("#top").mouseenter(
        function() {
          $("h1").addClass("titleHover");
          $("h1 + p").addClass("titleHoverP");
          console.log("yeah");
        },
      );
      // $("#menu-item-108").mouseenter(
      //   function() {
      //     $("h1").addClass("titleHover");
      //     $("h1 + p").addClass("titleHoverP");
      //     console.log("yeah");
      //   },
      // );
      // $("#menu-item-107").mouseenter(
      //   function() {
      //     $("h1").addClass("titleHover");
      //     $("h1 + p").addClass("titleHoverP");
      //     console.log("yeah");
      //   },
      // );
      $("#top").mouseout(
        function() {
          $("h1").removeClass("titleHover");
          $("h1 + p").removeClass("titleHoverP");
          console.log("nope");
        },
      );
      // $("#menu-item-108").mouseout(
      //   function() {
      //     $("h1").removeClass("titleHover");
      //     $("h1 + p").removeClass("titleHoverP");
      //     console.log("yeah");
      //   },
      // );
      // $("#menu-item-107").mouseout(
      //   function() {
      //     $("h1").removeClass("titleHover");
      //     $("h1 + p").removeClass("titleHoverP");
      //     console.log("yeah");
      //   },
      // );



      $(window).scrollTop(function()
        {
              // $( "h1" ).addClass( "titleHover" );

        }
      );
})( jQuery );


// if($( window ).scrollTop()>0{
// -
// }
// else{console.log("top");
//        $( "h1" ).removeClass( "titleHover" );}

// (function($) {
//   $(document).ready(function() {
//     $('.variable-width').slick({
//       infinite: true,
//       speed: 300,
//       slidesToShow: 1,
//       variableWidth: true,
//       autoplaySpeed: 2000
//     });
//
//   $('.single-item').slick();
//
//     if (window.matchMedia("(max-width: 768px)").matches) {
//       console.log("switch");
//       $('.single-item').addClass("variable-width temp");
//       $('temp').removeClass("single-item");
//
//       $('.single-item').slick('unslick');
//       $('.variable-width').slick({
//         infinite: true,
//         speed: 300,
//         slidesToShow: 1,
//         variableWidth: true,
//         autoplaySpeed: 2000
//       });
//     }
//     else {
//       console.log("switch");
//       $('.temp').addClass("single-item");
//       $('.temp').removeClass("variable-width");
//
//       $('.temp').slick('unslick');
//       $('.single-item').slick();
//     }
//   });
// })( jQuery );


// (function($) {
//   $(document).ready(function() {
//     $('.menu').hover(
//       function(e) {
//         console.log("yeah");
//         $('h1').trigger(e.type);
// })( jQuery );
